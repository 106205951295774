import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import nextPageBtn from "images/next-page-button.png"
import c1xLogo from 'images/c1x-logo.png'
import vponLogo from 'images/vpon-logo.png'
import inventecLogo from 'images/inventec-logo.png'

import SEO from "../components/seo"

const linkStyle = css`
  color: #273C57;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  position:relative;
`

const WorkInfoBlock = styled.div`
  width: 31%;
  display: block;
  padding-right: 3%;
  @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1366px) {
    width: 34%;
    padding-right: 4%;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    padding: 0 0 10% 0;
  } 
`
const WorkDescription = styled.p`
  font-size: 1.1rem;
  @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1366px) {
      font-size: 1rem;
  }
`

const CompanyTitle = (props) => {
  const { t, i18n } = useTranslation()

  return (
    <div css={css`
      margin: 1.8vh 0 2.5vh;
      box-shadow: 10px 8px 15px 2px rgba(0, 0, 0, 0.3);
      width: 100%;
      text-overflow: clip;
      white-space: nowrap; 
      overflow: hidden;
    `}>
      <p css={css`
        background: #273C57;
        color: white;
        text-align: center;
        font-size: calc(1em + 1.1vw);
        font-style: italic;
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
          font-size: calc(1em + 0.8vw);
        }
      `}>
        {t(`experience.companyTitle.${props.title}`)}
      </p>
    </div>
  )
}


const Experience = ({ pageContext }) => {
  const { t, i18n } = useTranslation()

  return(
    <div css={css`
      background: #ae68bc;
      height: 100vh;
      display: flex;
      @media (min-width: 320px) and (max-width: 480px) {
        height: 100%;
        padding-top: 80px;
      }
    `}>
      <div css={css`
        background-color: #eaeced;
        margin: 0 40px 0 0;
        padding: 3% 4% 3%;
        display: grid;
        overflow: auto;
        grid-template-columns: auto;
        grid-template-rows: 17% auto 20%;
        grid-row-gap: 20px;
        grid-template-areas:
          "title"
          "experience-content"
          "next-page";
        @media (min-width: 320px) and (max-width: 480px) {
          margin: 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 3% 6% 7%;
        }
      `}>
        <SEO title="Experience" />
        <h1 css={css`
          grid-area: title;
          align-self: end;
          @media (min-width: 320px) and (max-width: 480px) {
            font-size: calc(1em + 4vw);
            margin: 1.5rem auto;
          }
        `}>
          Experience
        </h1>
        <div css={css`
          grid-area: experience-content;
          padding-left: 10%;
          display: flex;
          @media only screen 
          and (min-device-width : 768px) 
          and (max-device-width : 1366px) {
            padding-left: 7%;
          }
          @media (min-width: 320px) and (max-width: 480px) {
            display: block;
            padding: 0;
          }
        `}>
          <WorkInfoBlock>
            <img src={c1xLogo} alt="C1X Logo" />
            <CompanyTitle title={"c1x"} />
            <WorkDescription>
              {t('experience.c1x')}
            </WorkDescription>
          </WorkInfoBlock>
          <WorkInfoBlock>
            <img src={vponLogo}
              css={css`
                height: 47px;
              `}
              alt="Vpon Logo"
            />
            <CompanyTitle title={"vpon"} />
            <WorkDescription>
              {t('experience.vpon')}
            </WorkDescription>
          </WorkInfoBlock>
          <WorkInfoBlock>
            <img src={inventecLogo} 
              css={css`
                height: 47px;
              `}
              alt="Inventec Logo"
            />
            <CompanyTitle title={"inventec"} />
            <WorkDescription>
              {t('experience.inventec')}
            </WorkDescription>
          </WorkInfoBlock>
        </div>
        <div css={css`
          grid-area: next-page;
          align-self: start;
          padding-left: 2vw;
          @media (min-width: 1100px) {
            padding-top: 1vw;
          }
          @media (min-width: 320px) and (max-width: 480px) {
            padding-left: 0;
          }
        `}>
          <AniLink swipe direction="up" to="/projects"
            css={linkStyle}
          >
            <img
              src={nextPageBtn}
              alt="Next Page"
              css={css`
                filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.2));
              `}
            />
            <span css={css` 
              position: absolute;
              width: 150px;
              margin-left: -18%;
              margin-top: 30%; 
            `}>03 {t('navbar.project')}</span>
          </AniLink>
        </div>
      </div>
    </div>
  )
}

export default Experience
